//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Avatar from '@/components/Avatar'
export default {
    props: ['data', 'entity_id'],
    data() {
        return {
            isLoading: true,
            isMember: false,
            isCancelling: false,
            isInviting: false,
            hasActiveInvite: false,
            extraLabel: 'Loading user info..'
        }
    },
    methods: {
        invite() {
            this.isInviting = true;
            this.extraLabel = "Sending invite...";
            this.$http({
                url: 'entities/' + this.entity_id + '/invite/' + this.data.user_id,
                method: 'POST'
            }).then((response) => {
                if (response.data['status'] == 'user_invited') {
                    this.inviteSent();
                } else if (response.data['status'] == 'already_member') {
                    this.isInviting = false;
                    this.$q.notify({
                        message: 'This user is already a member of this group.',
                        color: 'primary'
                    });
                } else if (response.data['status'] == 'has_invite') {
                    this.isInviting = false;
                    this.$q.notify({
                        message: 'There is already an active invite. Waiting for response..',
                        color: 'primary'
                    });
                } else {
                    this.$q.notify({
                        message: 'An error occurred while trying to invite.',
                        color: 'negative'
                    })
                }
            });
        },
        cancel() {
            this.isCancelling = true;
            this.extraLabel = "Cancelling invite..";
            this.$http({
                url: 'entities/' + this.entity_id + '/cancel/' + this.data.user_id,
                method: 'POST'
            }).then((response) => {
                if (response.data['status'] == 'user_kicked') {
                    this.isCancelling = false;
                    this.hasActiveInvite = false;
                    this.isInviting = false;
                    this.extraLabel = "You have cancelled the invitation.";
                    this.$q.notify({
                        message: 'Invite to group has been cancelled.',
                        color: 'positive'
                    });
                } else {
                    this.$q.notify({
                        message: 'There was an error while trying to cancel invite.',
                        color: 'negative'
                    })
                }
            }).catch(() => {
                this.$q.notify({
                    message: 'There was an error while trying to cancel invite.',
                    color: 'negative'
                });        
            });
        },
        inviteSent() {
            this.hasActiveInvite = true;
            this.extraLabel = "Invite has been sent. Waiting for response..";
            this.$q.notify({
                message: 'Invite was successfully sent to ' + this.data.first_name,
                color: 'positive'
            });
        }
    },
    created() {
        this.$http({
            url: 'users/' + this.data.user_id + '/ismember/' + this.entity_id,
            method: 'GET'
        }).then((response) => {
            if (response.data['status'] == 'member') {
                this.isMember = true;
                this.isLoading = false;
                this.extraLabel = 'Already a member of this group.';
            } else if (response.data['status'] == 'active_invite'){
                this.isMember = false;
                this.isLoading = false;
                this.hasActiveInvite = true;
                this.extraLabel = 'Invite has been sent. Waiting for response..';
            } else if (response.data['status'] == 'not_member'){
                this.isMember = false;
                this.isLoading = false;
                this.extraLabel = 'Click the invite button to invite';
            }
        }).catch((err) => {
            if (err.response.status == 400) {
                this.extraLabel = "There was a problem connecting to server."
            }
        })
    },
    components: {
        Avatar
    }
}
